<template>
  <div class="abcd">
    <img src="../../content/images/favicon.svg" id="logo" alt="smartyfile" />

    <h1>Session Expired</h1>

    <p>Your session has expired due to inactivity.</p>

    <p>
      Please
      <a class="session-expired login" v-on:click="goBack">login</a> again
    </p>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "SessionExpired",
  methods: {
    ...mapActions("auth", ["expireSession"]),
    goBack() {
      this.$router.back();
    },
  },
  mounted() {
    this.expireSession();
  },
};
</script>

<style lang="scss" scoped>
#logo {
  width: 300px;
  animation: 5s infinite ease-in-out alternate b-icon-animation-fade;
}
.abcd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
